@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
.fc-event {
  border: none;
  border-radius: 26px;
  padding: 0rem 0.3rem;
  margin-top: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
  transition: 0.2s;
}

.taskListCalendar .fc-event {
  border-radius: 0;
}

.fc-h-event {
  border: 2px solid transparent;
}

.fc-event-dragging {
  opacity: 1 !important;
}

.fc-toolbar-chunk {
  display: flex;
}

.fc-toolbar-title {
  color: #ffffff;
  font-size: 1.5rem !important;
}

.taskListCalendar .fc-toolbar-title {
  color: #667280;
}

.fc-timeline-slot-frame,
.fc-datagrid-cell-main {
  color: #667280;
}

.projectCalendar th.fc-timeline-slot.fc-timeline-slot-label.fc-day {
  height: 33px !important;
  vertical-align: middle;
}

.projectCalendar th.fc-timeline-slot.fc-timeline-slot-label.fc-day .fc-timeline-slot-frame {
  max-height: 33px !important;
  vertical-align: middle;
}

.fc-toolbar-chunk {
  align-items: center;
}

.fc-header-toolbar {
  background: #f3f4f6;
  padding: 0.5rem 0.5rem;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0rem;
}

.fc-timeline-header-row {
  background: #f3f4f6;
}

.fc-timeline-slot-frame {
  height: 50px !important;
}
.fc-scrollgrid-section th {
  background-color: #f3f4f6;
}

.fc-datagrid-header {
  border: 2px solid #f3f4f6;
}
.fc th {
  height: 50px !important;
}

.fc {
  max-height: calc(100vh - 65px);
}

.fc-event-time {
  color: #ca8a04;
}

.fc-theme-standard td {
  vertical-align: middle;
}

.fc-license-message {
  display: none;
}

.fc .fc-button-primary {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #375570 !important;
  transition: 00.2s;
  height: 40px !important;
}

.taskListCalendar .fc .fc-button-primary {
  color: #667280 !important;
  background-color: #edeef0 !important;
  border-color: #aeaeb6 !important;
  transition: 0.2s;
  height: 40px !important;
}

.taskListCalendar .fc .fc-button-primary:hover {
  background-color: #ceced3 !important;
  transition: 0.2s;
}

.fc .fc-button-primary:hover {
  background-color: #86a8c5 !important;
  transition: 0.2s;
  color: #fff !important;
}

.ganttfilter {
  margin-left: 1rem;
}

.fc .fc-today-button {
  color: #000 !important;
  background-color: #ffffff !important;
  border-color: #375570 !important;
}

.fc .fc-button-active {
  background-color: #375570 !important;
  color: #ffffff !important;
  border-color: #375570 !important;
}

.taskListCalendar .fc .fc-button-active {
  background-color: #ceced3 !important;
}

.fc-header-toolbar {
  background-color: #6888a5 !important;
}

.taskListCalendar .fc-header-toolbar {
  background-color: #f3f4f6 !important;
}

.fc-resource-timeline-divider {
  width: 0.5em !important;
}

.taskListCalendar .fc-datagrid-cell.fc-datagrid-cell-super {
  background-color: #f3f4f6 !important;
  color: #667280 !important;
}

.fc .fc-timeline-now-indicator-line {
  border-width: 0px 0px 0px 3px;
  bottom: 0px;
  margin: 0px -2px;
}

.fc .fc-timeline-lane {
  background: linear-gradient(to top, #eee 0%, #ccc 50%, #eee 100%);
  border-color: #b9bfc7;
}

.taskListCalendar .fc .fc-timeline-lane {
  background: none;
  border-color: inherit;
}

.fc .fc-timeline-slot-lane {
  border: 1px solid #b9bfc7;
}

.fc .fc-bg-event {
  background-color: white;
  opacity: 65%;
  border-radius: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.fc-timeline-lane-frame {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.fc-res-group {
  font-weight: bold !important;
}

.projectCalendar .fc-datagrid-cell.fc-resource.fc-res-group {
  background-color: #c2a584 !important;
}

.taskListCalendar .fc-datagrid-cell.fc-resource.fc-res-group span {
  font-weight: bold;
}

.taskListCalendar .fc-datagrid-cell.fc-resource.selected-row {
  background-color: #86a8c5 !important;
}

.taskListCalendar .fc-datagrid-cell.fc-resource.selected-row {
  background-color: #ffeacb !important;
}

/*.fc-scrollgrid .fc-datagrid-cell-frame {
  background-color: #6888a5;
  color: #ffffff;
  transition: 0.2s;
}*/

.fc-datagrid-cell {
  background-color: #6888a5 !important;
  color: #ffffff !important;
  transition: 0.2s !important;
}

 .taskListCalendar .fc-datagrid-cell {
  background-color: white !important;
  transition: 0.2s !important;
}
.fc-datagrid-body .fc-datagrid-cell-frame:hover {
  background-color: #86a8c5;
  transition: 0.2s;
  cursor: pointer;
}

.taskListCalendar .fc-datagrid-cell-frame:hover {
  background-color: #fff7ea !important;
  transition: 0.2s;
  cursor: pointer;
}

.fc-datagrid-cell-frame span {
  color: #ffffff;
  font-weight: bold;
}

.taskListCalendar .fc-datagrid-cell-frame span {
  color: #667280;
  font-weight: 300;
  user-select: none;
}

.fc-datagrid-body .fc-scrollgrid-sync-table {
  background: #375570;
}

.projectCalendar .fc-timeline-event-harness,
.employeeCalendar .fc-timeline-event-harness {
  top: 0 !important;
}
.projectCalendar .fc-resource-timeline table tbody tr .fc-datagrid-cell div,
.employeeCalendar .fc-resource-timeline table tbody tr .fc-datagrid-cell div {
  height: 38px !important;
}
.projectCalendar .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events,
.employeeCalendar .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
  padding: 0 !important;
  height: 38px !important;
}

.fc-bg-event:has(> .workHourEvent) {
  font-weight: bold;
  z-index: 3 !important;
}
.workHourEvent {
  padding-top: 36px;
  display: flex;
  justify-content: center;
}
.withWorkHour .fc-resource-timeline table tbody tr .fc-datagrid-cell div {
  height: 60px !important;
}
.withWorkHour .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
  padding: 0 !important;
  height: 60px !important;
}

.machineCalendar td.fc-day.fc-day-sun,
.machineCalendar td.fc-day.fc-day-sat,
.projectCalendar td.fc-day.fc-day-sun,
.projectCalendar td.fc-day.fc-day-sat,
.employeeCalendar td.fc-day.fc-day-sat,
.employeeCalendar td.fc-day.fc-day-sun {
  background-color: #fde68a;
}

.taskListCalendar .projectLine {
  position: relative;
  margin-top: 0rem !important;
  pointer-events: none;
}

.taskListCalendar .projectLine:before {
  content: '';
  position: absolute;
  clip-path: polygon(50% 0, 0 0, 0 100%);
  bottom: -10px;
  left: -2px;
  background: inherit;
  width: 30px;
  height: 15px;
}

.taskListCalendar .projectLine:after {
  content: '';
  position: absolute;
  clip-path: polygon(50% 0, 100% 0, 100% 100%);
  bottom: -10px;
  right: -3px;
  background: inherit;
  width: 30px;
  height: 15px;
}

.taskListCalendar td.fc-day.fc-day-sun,
.taskListCalendar td.fc-day.fc-day-sat {
  background-color: #f7f7f7;
}

.datagrid-line-background {
  background-color: rgb(219, 128, 128) !important;
}

.projectCalendar .fc-event,
.employeeCalendar .fc-event {
  margin-top: 0.1rem;
}

.projectCalendar .extraCol {
  width: 35px !important;
  text-align: center;
}

.projectCalendar .biggerCol {
  width: 110px !important;
  text-align: center;
}

.projectCalendar .datebiggerCol {
  width: 120px !important;
  text-align: center;
}

.projectCalendar .buhneBiggerCol {
  width: 180px !important;
  text-align: center;
}

.projectCalendar .buhneNintyDaysBeforeExpiry {
  background-color: orange !important;
}

.projectCalendar .buhneExpired {
  background-color: red !important;
}

.projectCalendar .hide {
  display: none;
}
.tab-scroll.custom-scrollbar::-webkit-scrollbar {
  height: 10px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

@supports (-moz-appearance: none) {
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.4) transparent;
  }
}

.project-leader span {
  color: #bef264 !important;
}

.quit-status span {
  color: #fef08a !important;
}

.has-no-leader span {
  color: #ffbef5 !important;
}

.has-leader span {
  margin-left: 0.2rem;
}

.fc-datagrid-header {
  background-color: #6888a5 !important;
}

.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7 {
  padding-top: 5px !important;
  text-align: center !important;
}

.taskListCalendar .col {
  padding-top: 5px !important;
  text-align: center !important;
}

.taskListCalendar .preparednessCol {
  position: relative;
}

.projectCalendar .fc-datagrid-header .fc-datagrid-cell-resizer {
  resize: none !important;
  cursor: default !important;
  pointer-events: none;
}

.projectCalendar .col0 .fc-datagrid-cell-resizer {
  background-color: #6888a5 !important;
  height: 48px !important;
}

.taskListCalendar .col,
.taskListCalendar .col0 {
  background-color: #f3f4f6 !important;
}

.taskListCalendar .col span,
.taskListCalendar .col0 span {
  color: #667280 !important;
}

.col1,
.col2,
.col3 {
  width: 35.5px !important;
  position: absolute;
}

.col4 {
  width: 110px !important;
  position: absolute;
}

.highlight-empty-parent {
  background: rgb(191, 223, 191) !important;
}

.highlight-empty-parent:hover {
  background: rgb(157, 207, 157) !important;
}
.col5 {
  width: 110px !important;
  position: absolute;
}

.col6 {
  width: 120px !important;
  position: absolute;
}

.sapresourcecalendar .extraCol {
  width: 35px !important;
}

.sapresourcecalendar .hide .fc-datagrid-cell-frame {
  display: none;
  border-color: #6888a5 !important;
}
.sapresourcecalendar .hide {
  border-color: #6888a5 !important;
}

.sapresourcecalendar .bgRed .fc-datagrid-cell-frame {
  background-color: red !important;
}

.sapresourcecalendar .fc .fc-bg-event {
  border-radius: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
  background: linear-gradient(to top, #eee 0%, #ccc 50%, #eee 100%) !important;
}

.sapresourcecalendar .fc .fc-timeline-lane {
  background: white !important;
  border-color: #b9bfc7;
}

.col7 {
  width: 180px !important;
  position: absolute;
}

.MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader {
  background-color: #b4c9d4 !important;
  padding-bottom: 5px !important;
}

.MuiDataGrid-root--densityCompact .MuiDataGrid-headerFilterRow .MuiFormControl-root {
  font-size: small;
}

.MuiDataGrid-root--densityCompact .MuiDataGrid-headerFilterRow input {
  font-size: small;
}

.MuiDataGrid-root--densityCompact .MuiDataGrid-headerFilterRow .MuiSelect-select {
  font-size: small;
  padding-top: 0;
  padding-bottom: 0;
}

.MuiDataGrid-root--densityCompact .MuiDataGrid-headerFilterRow .MuiInputBase-root {
  margin-top: 8px;
}

.MuiDataGrid-root--densityCompact .MuiDataGrid-headerFilterRow .MuiFormLabel-root {
  font-size: small;
}

.MuiDataGrid-root--densityCompact .MuiDataGrid-headerFilterRow .MuiIconButton-sizeSmall {
  margin-bottom: 0;
}

.MuiDataGrid-columnHeader {
  background-color: #cedfe8;
}

.tableReport .MuiDataGrid-columnHeaderTitle {
  font-weight: bolder !important;
  color: #667280 !important;
}

.calendarView .fc-col-header .fc-col-header-cell {
  vertical-align: middle;
}

.calendarView .fc-event-time {
  color: white;
  font-weight: bold;
  font-size: 0.9rem !important;
}

.calendarView .fc-event-title {
  font-size: 1.1rem !important;
}

.calendarView .fc-daygrid-day-frame .fc-event-title {
  font-size: 0.9rem !important;
  color: white;
}

.calendarView .fc-daygrid-day-frame .fc-event-time {
  color: white;
}

.calendarView .fc-event {
  background: rgba(59, 130, 246, 0.5);
  border: none;
  border-left: 3px solid rgba(59, 130, 246, 1);
  border-radius: 0;
  transition: 0.3s;
}

.calendarView .fc-event:hover {
  transition: 0.3s;
  background: rgba(59, 130, 246, 0.7);
  cursor: pointer;
}

.calendarView .fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none;
}

.split {
  display: flex;
  flex-direction: row;
  height: 80vh;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.notistack-SnackbarContainer {
  z-index: 10000 !important;
}
.react-flow__node.selected {
  box-shadow: none;
}

.react-flow__handle.connectionindicator {
  background-color: white;
  border: 1px solid rgb(53, 53, 53);
  height: 6px;
  width: 6px;
}

.react-flow__edge-path {
  stroke: rgb(53, 53, 53) !important;
}
